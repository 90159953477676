.header__webLinks {
  display: flex;
  flex-direction: row;
}
.header__button {
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: none;
}
.header__buttonText {
  color: lightgray;
  font-size: 20px;
}
.header__link {
  width: 120px;
  height: 60px;
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (max-width: 900px) {
  .header__webLinks {
    display: none;
  }
  .header__buttonText {
    font-size: 32px;
  }
}
@media only screen and (max-height: 400px) {
  .header__webLinks {
    display: none;
  }
}
