.certification {
  display: flex;
  width: 40%;
  margin: 2%;
  align-items: center;
  justify-content: center;
}
.certification__title {
  flex-wrap: wrap;
  width: 100%;
  font-size: 23px;
  font-weight: 600;
  color: #3975a7;
}
.certification__thumbnail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.certification__link {
  text-align: center;
  width: 100%;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 800px) {
  .certification {
    width: 80%;
  }
}
@media only screen and (max-width: 500px) {
  .certification__title {
    font-size: 26px;
  }
}
