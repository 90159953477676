.certifications__container {
  width: 100%;
  align-items: center;
  margin: 20px;
  justify-content: center;
}
.certifications__container::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 500px) {
  .certifications__container {
    margin: 0px;
    margin-top: 20px !important;
  }
}
