.loadingSpinner {
  display: flex;
  justify-content: center;
  margin-top: 300px;
  margin-bottom: 300px;
}
@media only screen and (max-width: 800px) {
  .loadingSpinner {
    margin-bottom: 500px;
  }
}
@media only screen and (max-width: 500px) {
  .loadingSpinner {
    margin-top: 225px;
    margin-bottom: 400px;
  }
}
@media only screen and (max-height: 400px) {
  .loadingSpinner {
    margin-top: 13px;
  }
}
