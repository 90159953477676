.header__linkHome {
  width: 120px;
  background: none;
}
.header__buttonHome {
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: none;
  margin: 3px;
}

.header__buttonHomeText {
  color: lightgray;
  font-size: 40px;
  font-weight: 600;
}
@media only screen and (max-width: 900px) {
  .header__buttonHomeText {
    font-size: 32px;
    font-weight: 600;
  }
}
