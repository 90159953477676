.resume__link {
  text-decoration: none;
}
.resume__thumbnail {
  width: 550px;
  object-fit: contain;
}

@media only screen and (max-width: 500px) {
  .resume__thumbnail {
    width: 300px;
    object-fit: contain;
  }
}
