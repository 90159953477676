.project {
  display: flex;
  width: 40%;
  margin: 2%;
  align-items: center;
  justify-content: center;
}
.project__title {
  flex-wrap: wrap;
  width: 100%;
  font-size: 23px;
  font-weight: 600;
  color: #3975a7;
}
.project__thumbnail {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.project__link {
  text-align: center;
  width: 100%;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 900px) {
  .project {
    width: 85%;
  }
}
