.home__mainCenter {
  margin-top: 285px;
}
.home__title {
  font-size: 75px;
  color: #3975a7;
  text-align: center;
}
.home__subtitle {
  font-size: 40px;
  text-align: center;
  color: #3975a7;
  font-weight: 400;
}
.home__subtitleDetails {
  font-size: 25px;
  text-align: center;
  color: #3975a7;
  font-weight: 400;
  font-style: italic;
}
.home__lineBreak {
  border-color: #3975a7;
  border-style: solid;
  border-width: 1px;
  background: none;
  margin-bottom: 10px;
  width: 500px;
}
@media only screen and (max-width: 500px) {
  .home__title {
    font-size: 50px;
    font-weight: 700;
  }
  .home__lineBreak {
    width: 335px;
  }
  .home__subtitle {
    font-size: 32px;
  }
  .home__subtitleDetails {
    font-size: 21px;
  }
  .home__mainCenter {
    margin-top: 0px;
  }
}
@media only screen and (max-height: 400px) {
  .home__mainCenter {
    margin-top: 40px;
  }
}
