.header__mobileMenuButton {
  border: none;
  color: lightgray;
  width: 100px;
  height: 50px;
  z-index: 1000;
}
.header__mobileMenuButtonIcon {
  color: lightgray;
  cursor: pointer;
}
.header__mobileMenuButtonContainer {
  display: none;
}
@media only screen and (max-width: 900px) {
  .header__mobileMenuButtonContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .header__mobileMenuButton {
    background: none;
  }
}
@media only screen and (max-height: 400px) {
  .header__mobileMenuButtonContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
