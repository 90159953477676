.certifications__row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 800px) {
  .certifications__row {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
