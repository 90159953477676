.resumePic__page {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
}
.resumePic__image {
  max-width: 100%;
  display: flex;
  object-fit: contain;
}
@media only screen and (max-width: 500px) {
  .resumePic__image {
    margin-top: 15%;
  }
}
