* {
  margin: 0;
}
.home__page {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
@media only screen and (max-width: 500px) {
  body > #root > div {
    height: 100%;
  }
  .home__page {
    overflow: hidden;
  }
}
@media only screen and (max-height: 400px) {
}
