.pageTitle {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #3975a7;
  padding: 10px;
  text-decoration: underline;
}
@media only screen and (max-width: 500px) {
  .pageTitle {
    font-size: 33px;
  }
}
