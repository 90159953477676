* {
  margin: 0;
}
.header {
  background-color: #3975a7;
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.header__container {
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header__icon {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}
.header__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}
.mobileMenu__button {
  color: lightgray;
  padding: 7px;
  border: none;
  cursor: pointer;
  background: none;
}
.header__mobileLink {
  margin: 10px;
}
.mobileMenu {
  display: none;
}
@media only screen and (max-width: 900px) {
  .header__mobileButtonText {
    font-size: 28px;
    color: lightgray;
  }
  .mobileMenu {
    display: flex;
  }
}
@media only screen and (max-height: 400px) {
  .header {
    padding-bottom: 10px;
  }
}
