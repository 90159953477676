.apps__row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.apps__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}
.apps__itemLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.apps__itemTitle {
  text-align: center;
  font-size: 25px;
  margin-bottom: 5%;
}
.apps__itemThumbnail {
  height: 65vh;
  object-fit: contain;
}
@media only screen and (max-width: 1200px) {
  .apps__itemThumbnail {
    height: 50vh;
  }
}
@media only screen and (max-width: 1000px) {
  .apps__row {
    flex-direction: column;
  }
  .apps__item {
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .apps__itemThumbnail {
    height: 75vh;
  }
}
