.aboutMe__section {
  margin-bottom: 10px;
  width: 67%;
  text-align: center;
  margin-top: 15px;
}
.aboutMe__sectionTitle {
  font-size: 25px;
  font-weight: 375;
  color: #3975a7;
  margin-bottom: 5px;
  font-weight: 600;
}
.aboutMe__sectionInfo {
  font-size: 19px;
  color: #3975a7;
  font-weight: 500;
}
@media only screen and (max-width: 500px) {
  .aboutMe__section {
    width: 94%;
  }
  .aboutMe__sectionInfo {
    font-size: 17px;
  }
}
@media only screen and (max-height: 400px) {
  .aboutMe__section {
    width: 80%;
  }
}
