.home__container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
@media only screen and (max-width: 500px) {
  .home__container {
    height: 100%;
    margin-top: 60%;
    padding: 0px;
  }
}
@media only screen and (max-height: 400px) {
  .home__container {
    padding-top: 20px;
    height: 100%;
  }
}
