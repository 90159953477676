@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
* {
  margin: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-family: "Rokkitt";
}
*::-webkit-scrollbar {
  display: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: lightgray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  margin-top: 300px;
  margin-bottom: 300px;
}
@media only screen and (max-width: 800px) {
  .loadingSpinner {
    margin-bottom: 500px;
  }
}
@media only screen and (max-width: 500px) {
  .loadingSpinner {
    margin-top: 225px;
    margin-bottom: 400px;
  }
}
@media only screen and (max-height: 400px) {
  .loadingSpinner {
    margin-top: 13px;
  }
}

.pageTitle {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #3975a7;
  padding: 10px;
  text-decoration: underline;
}
@media only screen and (max-width: 500px) {
  .pageTitle {
    font-size: 33px;
  }
}

.portfolio__row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.github__link {
  align-items: center;
  text-decoration: none;
  margin-left: 10%;
  margin-right: 10%;
}
.project__title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #3975a7;
  margin-bottom: 12px;
}
.project__link {
  text-decoration: none;
}
.portfolio__icon {
  color: #3975a7;
}
@media only screen and (max-width: 500px) {
  .portfolio__row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .github__link {
    margin-top: 10px;
  }
}

.project {
  display: flex;
  width: 40%;
  margin: 2%;
  align-items: center;
  justify-content: center;
}
.project__title {
  flex-wrap: wrap;
  width: 100%;
  font-size: 23px;
  font-weight: 600;
  color: #3975a7;
}
.project__thumbnail {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.project__link {
  text-align: center;
  width: 100%;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 900px) {
  .project {
    width: 85%;
  }
}

.portfolio__row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 900px) {
  .portfolio__row {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

.portfolio__projects {
  display: flex;
  flex-direction: column;
}

.portfolio__container {
  width: 90%;
  padding: 20px;
}

.apps__row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.apps__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}
.apps__itemLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.apps__itemTitle {
  text-align: center;
  font-size: 25px;
  margin-bottom: 5%;
}
.apps__itemThumbnail {
  height: 65vh;
  object-fit: contain;
}
@media only screen and (max-width: 1200px) {
  .apps__itemThumbnail {
    height: 50vh;
  }
}
@media only screen and (max-width: 1000px) {
  .apps__row {
    flex-direction: column;
  }
  .apps__item {
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .apps__itemThumbnail {
    height: 75vh;
  }
}

.header__linkHome {
  width: 120px;
  background: none;
}
.header__buttonHome {
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: none;
  margin: 3px;
}

.header__buttonHomeText {
  color: lightgray;
  font-size: 40px;
  font-weight: 600;
}
@media only screen and (max-width: 900px) {
  .header__buttonHomeText {
    font-size: 32px;
    font-weight: 600;
  }
}

.header__mobileMenuButton {
  border: none;
  color: lightgray;
  width: 100px;
  height: 50px;
  z-index: 1000;
}
.header__mobileMenuButtonIcon {
  color: lightgray;
  cursor: pointer;
}
.header__mobileMenuButtonContainer {
  display: none;
}
@media only screen and (max-width: 900px) {
  .header__mobileMenuButtonContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .header__mobileMenuButton {
    background: none;
  }
}
@media only screen and (max-height: 400px) {
  .header__mobileMenuButtonContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.header__webLinks {
  display: flex;
  flex-direction: row;
}
.header__button {
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: none;
}
.header__buttonText {
  color: lightgray;
  font-size: 20px;
}
.header__link {
  width: 120px;
  height: 60px;
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (max-width: 900px) {
  .header__webLinks {
    display: none;
  }
  .header__buttonText {
    font-size: 32px;
  }
}
@media only screen and (max-height: 400px) {
  .header__webLinks {
    display: none;
  }
}

* {
  margin: 0;
}
.header {
  background-color: #3975a7;
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.header__container {
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header__icon {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}
.header__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}
.mobileMenu__button {
  color: lightgray;
  padding: 7px;
  border: none;
  cursor: pointer;
  background: none;
}
.header__mobileLink {
  margin: 10px;
}
.mobileMenu {
  display: none;
}
@media only screen and (max-width: 900px) {
  .header__mobileButtonText {
    font-size: 28px;
    color: lightgray;
  }
  .mobileMenu {
    display: flex;
  }
}
@media only screen and (max-height: 400px) {
  .header {
    padding-bottom: 10px;
  }
}

.portfolio__page {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.certification {
  display: flex;
  width: 40%;
  margin: 2%;
  align-items: center;
  justify-content: center;
}
.certification__title {
  flex-wrap: wrap;
  width: 100%;
  font-size: 23px;
  font-weight: 600;
  color: #3975a7;
}
.certification__thumbnail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.certification__link {
  text-align: center;
  width: 100%;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 800px) {
  .certification {
    width: 80%;
  }
}
@media only screen and (max-width: 500px) {
  .certification__title {
    font-size: 26px;
  }
}

.certifications__row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 800px) {
  .certifications__row {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

.certifications__container {
  width: 100%;
  align-items: center;
  margin: 20px;
  justify-content: center;
}
.certifications__container::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 500px) {
  .certifications__container {
    margin: 0px;
    margin-top: 20px !important;
  }
}

.certifications__page {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resume__link {
  text-decoration: none;
}
.resume__thumbnail {
  width: 550px;
  object-fit: contain;
}

@media only screen and (max-width: 500px) {
  .resume__thumbnail {
    width: 300px;
    object-fit: contain;
  }
}

.resume__container {
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  padding: 20px;
}

.resume__page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact__socialLink {
  text-decoration: none;
  margin: 2px;
}
.contact__linkTitle {
  margin-left: 7px;
  color: #3975a7;
}
.icon {
  color: #3975a7;
}
@media only screen and (max-width: 500px) {
  .contact__linkTitle {
    font-size: 23px;
  }
}

.contact__socialMediaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.contact__page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home__mainCenter {
  margin-top: 285px;
}
.home__title {
  font-size: 75px;
  color: #3975a7;
  text-align: center;
}
.home__subtitle {
  font-size: 40px;
  text-align: center;
  color: #3975a7;
  font-weight: 400;
}
.home__subtitleDetails {
  font-size: 25px;
  text-align: center;
  color: #3975a7;
  font-weight: 400;
  font-style: italic;
}
.home__lineBreak {
  border-color: #3975a7;
  border-style: solid;
  border-width: 1px;
  background: none;
  margin-bottom: 10px;
  width: 500px;
}
@media only screen and (max-width: 500px) {
  .home__title {
    font-size: 50px;
    font-weight: 700;
  }
  .home__lineBreak {
    width: 335px;
  }
  .home__subtitle {
    font-size: 32px;
  }
  .home__subtitleDetails {
    font-size: 21px;
  }
  .home__mainCenter {
    margin-top: 0px;
  }
}
@media only screen and (max-height: 400px) {
  .home__mainCenter {
    margin-top: 40px;
  }
}

.home__container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
@media only screen and (max-width: 500px) {
  .home__container {
    height: 100%;
    margin-top: 60%;
    padding: 0px;
  }
}
@media only screen and (max-height: 400px) {
  .home__container {
    padding-top: 20px;
    height: 100%;
  }
}

* {
  margin: 0;
}
.home__page {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
@media only screen and (max-width: 500px) {
  body > #root > div {
    height: 100%;
  }
  .home__page {
    overflow: hidden;
  }
}
@media only screen and (max-height: 400px) {
}

.aboutMe__section {
  margin-bottom: 10px;
  width: 67%;
  text-align: center;
  margin-top: 15px;
}
.aboutMe__sectionTitle {
  font-size: 25px;
  font-weight: 375;
  color: #3975a7;
  margin-bottom: 5px;
  font-weight: 600;
}
.aboutMe__sectionInfo {
  font-size: 19px;
  color: #3975a7;
  font-weight: 500;
}
@media only screen and (max-width: 500px) {
  .aboutMe__section {
    width: 94%;
  }
  .aboutMe__sectionInfo {
    font-size: 17px;
  }
}
@media only screen and (max-height: 400px) {
  .aboutMe__section {
    width: 80%;
  }
}

.aboutMe__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.about__page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about__page::-webkit-scrollbar {
  display: none;
}

.resumePic__page {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
}
.resumePic__image {
  max-width: 100%;
  display: flex;
  object-fit: contain;
}
@media only screen and (max-width: 500px) {
  .resumePic__image {
    margin-top: 15%;
  }
}

* {
  font-family: "Special Elite", cursive;
  overflow-x: hidden;
}
body > #root > div {
  width: 100%;
  height: 100%;
}

