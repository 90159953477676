.portfolio__row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.github__link {
  align-items: center;
  text-decoration: none;
  margin-left: 10%;
  margin-right: 10%;
}
.project__title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #3975a7;
  margin-bottom: 12px;
}
.project__link {
  text-decoration: none;
}
.portfolio__icon {
  color: #3975a7;
}
@media only screen and (max-width: 500px) {
  .portfolio__row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .github__link {
    margin-top: 10px;
  }
}
