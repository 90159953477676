.contact__socialLink {
  text-decoration: none;
  margin: 2px;
}
.contact__linkTitle {
  margin-left: 7px;
  color: #3975a7;
}
.icon {
  color: #3975a7;
}
@media only screen and (max-width: 500px) {
  .contact__linkTitle {
    font-size: 23px;
  }
}
